import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import UnProjet from '../components/Common/UnProjet'
import { Helmet } from 'react-helmet'

const FAQ = () => {
    return (
        <Layout>
            <Helmet>
                <title>Seekreet | FAQ</title>
                <meta name="description" content="Seekreet, une équipe spécialisée dans le data management. Mise en conformité compliance, qualité, partage d’informations, nous digitalisons vos process." />
            </Helmet>
            <Navbar />

            <PageBanner
                pageTitle="FAQ" 
                homePageText="Accueil" 
                homePageUrl="/" 
                activePageText="FAQ" 
            />

            <div className="ptb-100">
                <div className="container">
                    <div className="faq-accordion">
                        <Accordion allowZeroExpanded preExpanded={['a']}>
                            
                            <AccordionItem uuid="a">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q1. Data management et data governance ?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>La <span className='bold'>data management</span> est étroitement lié à la data governance. Une stratégie de data management passe obligatoirement par la mise en place d'une gouvernance des données en amont.</p>
                                    <p>La <span className='bold'>data governance</span> regroupe les procédures permettant d'assurer la qualité des données au sein de l’entreprise. Elle consiste à maintenir des données correctes, fiables, actualisées et sécurisées.</p>
                                    <p>La data governance constitue ainsi <span className='bold'>la base de la stratégie de gestion des données de l’entreprise</span>. Le data management et la data governance sont des <span className='bold'>initiatives globales qui s’inscrivent sur du long terme</span>.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            
                            <AccordionItem uuid="b">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q2. Comment accéder à la plateforme Wispeek ?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Vous pouvez y accéder en cliquant sur le lien suivant.
                                    </p>
                                    <ul>
                                        <li><a href="https://agir.wispeek.com">Vous êtes salarié ?</a></li>
                                        <li><a href="https://gerer.wispeek.com">Vous êtes référent ?</a></li>
                                    </ul>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="c">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q3. Je souhaite créer un site de vente en ligne, comment faire ?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Vous voulez créer un site web ? Notre équipe vous propose un rendez-vous afin de cibler vos besoins et vous proposer la solution adéquate !<br></br>
                                        N'hésitez plus <a href="/contact">contactez-nous </a>!
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                        </Accordion>
                    </div>
                </div>
            </div>

            <UnProjet />
            
            <Footer />
        </Layout>
    );
}

export default FAQ